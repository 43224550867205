export const dialogdarkgrey = {
  50: '#e7e7e7',
  100: '#c2c2c2',
  200: '#999999',
  300: '#707070',
  400: '#525252',
  500: '#333333',
  600: '#2e2e2e',
  700: '#272727',
  800: '#202020',
  900: '#141414',
  A100: '#ef6e6e',
  A200: '#ea4040',
  A400: '#f60000',
  A700: '#dd0000',
  'contrastDefaultColor': 'light'
};
