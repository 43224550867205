export const dialogformfieldbkgd = {
  50: '#e5e5e5',
  100: '#bebebe',
  200: '#929292',
  300: '#666666',
  400: '#464646',
  500: '#252525',
  600: '#212121',
  700: '#1b1b1b',
  800: '#161616',
  900: '#0d0d0d',
  A100: '#eb6969',
  A200: '#e53c3c',
  A400: '#ee0000',
  A700: '#d40000',
  'contrastDefaultColor': 'light'
};

