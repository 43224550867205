export const brown = {
  50: 'efe8e1',
  100: 'd7c5b4',
  200: 'bc9f82',
  300: 'a1794f',
  400: '8c5c2a',
  500: '783f04',
  600: '703903',
  700: '653103',
  800: '5b2902',
  900: '481b01',
  A100: 'ff9f7c',
  A200: 'ff7a49',
  A400: 'ff5416',
  A700: 'fc4300',
  'contrastDefaultColor': 'light'
};

